<template>
  <!-- 开发人员：贺文娟 -->
  <div class="objectTableMain">
    <div class="object_box">
      <div class="pull-left left-content" style="padding-top: 10px">
        <p class="textStyle">
          {{ $t("label.campaign.channel.step7.send") }}
        </p>
      </div>

      <div class="pull-left topContent">
        <div class="pull-left left-content" style="padding-top: 10px">
          <input id="viewId" type="hidden" v-model="viewId" />
          <input id="ids" type="hidden" v-model="ids" />
          <!-- 切换视图 -->
          <choose-view
            class="pull-left choose-view"
            :object-name="tabName"
            :viewId="viewId"
            :view-list="viewList"
            :view-name="viewName"
            :tabStyle="tabStyle"
            @changeView="changeView"
            @getViewList="getViewList"
            :objId="objId"
            :prefix="prefix"
            :type="type"
            @refresh="refresh"
            @actives="actives"
            v-if="this.viewLength > 0"
          >
          </choose-view>
        </div>
        <!--  @activeChange="activeChange" -->
        <div class="" style="padding-bottom: 10px">
          <div class="pull-right right-content">
            <div class="buttonWrapper">
              <!-- 列表操作按钮 -->
              <div style="width: 100%; height: 30px">
                <span>
                  <el-button
                    type="primary createBtn pull-right"
                    size="mini"
                    style="
                      padding: 7px 12px;
                      margin-top: 1px;
                      border: 0;
                      margin-left: 10px;
                    "
                    @click="sendMile"
                    >{{ $t("label.import.page1.nextstep") }}</el-button
                  >
                  <ul class="top-btns pull-right">
                    <li class="btn pull-left">
                      <span class="btnName" @click="cancelMail">{{
                        $t("label.emailsync.button.cancel")
                      }}</span>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pull-left topContent">
        <div class="pull-left left-content" style="width: 40%">
          <!-- 列表表头说明文字 -->
          <statistics
            :total="totalSize"
            :isQueryCount="isQueryCount"
            :field="sortFieldName"
            :show-checked-length="type !== 'kanban'"
            :time-interval="timeInterval"
            :checked-length="
              ($refs.tablePanel && $refs.tablePanel.checkedList.length) ||
              lengths
            "
            @refresh="refresh"
          >
          </statistics>
        </div>

        <div
          class="pull-right right-content"
          style="padding-top: 0; width: 59%"
        >
          <!-- 视图相关操作按钮 -->
          <view-buttons
            ref="pullRight"
            class="pull-right"
            :show-buttons="showButtonsTest"
            :isTag="isTag"
            :tab-name="tabName"
            :type="type"
            :objId="objId"
            :viewId="viewId"
            :view-name="viewName"
            :reportList="reportList"
            :loadingReport="loadingReport"
            :ismodifyFalg="ismodifyFalg"
            :prefix="prefix"
            :viewInfos="viewInfo"
            :daochuUrl="daochuUrl"
            :totalSize="totalSize"
            :showScreen="showScreen"
            :isChart="isChart"
            @actives="actives"
            @getViewId="getViewId"
            @tableView="tableView"
            @boardView="boardView"
            @planView="planView"
            @filterBoard="filterBoard"
            @multiScreen="multiScreen"
            @tagManage="tagManage"
            @chartsBoard="chartsBoard"
            @print="print"
            @reportBoard="reportBoard"
          >
          </view-buttons>
          <!-- 搜索框 -->
          <search-wrapper
            ref="searchWrapper"
            width="160px"
            class="searchWrapper pull-right"
            :placeholder="$t('vue_label_commonobjects_view_searchthisview')"
            :exact-search-flag="exactSearchFlag"
            :exact-search-field-list="exactSearchFieldList"
            @changeKeyWord="changeKeyWord"
          >
          </search-wrapper>
        </div>
      </div>
      <!-- 列表 -->

      <table-panel
        class="text-center"
        ref="tablePanel"
        v-if="!showKanban && !showPlan"
        :border="true"
        :table-height="tableHeight"
        :show-index="true"
        :checked="true"
        :customHeader="true"
        :prefix="prefix"
        :obj-id="objId"
        :object-api="objectApi"
        :view-id="viewId"
        :view-modify="ismodifyFalg"
        :pageNum="pageNum"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :viewSelectedFieldList="viewSelectedFieldList"
        :viewUnselectedFieldList="viewUnselectedFieldList"
        :pict-loading="pictLoading"
        @searchViewDetail="searchViewDetail"
        @changeSortFiled="changeSortFiled"
        @wordWrap="wordWrap"
        @locked="locked"
        @edit="edit"
        @del="del"
        @append="append"
        @handleChange="handleChange"
        @saveSelectedField="saveSelectedField"
        @onchange="onchange"
        @refresh="refresh"
      >
      </table-panel>

      <!-- 看板视图 -->
      <kanban
        v-else-if="showKanban && !showPlan"
        ref="kanban"
        @chat="chat"
        @isChat="isChat"
        @longitude="longitude"
        @activeName="activeName"
        :tableHeight="tableHeight"
        :kanBanData="kanBanData"
        :selectedList="selectedList"
      >
      </kanban>

      <!--      计划-排班表-->
      <shift-plan
        ref="shiftPlan"
        :viewId="viewId"
        v-else-if="!showKanban && showPlan"
      >
      </shift-plan>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label.delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <span
          style="font-size: 16px; font-weight: bold; word-break: break-word"
        >
          <!-- 确认删除该条数据吗？ -->
          {{ delContent + "?" }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirmDel" type="primary" size="mini">
            <!-- 删除 -->
            {{ $t("label.delete") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 筛选器 -->
      <Filter-panel
        class="screen"
        v-if="showScreen"
        :viewInfos="viewInfo"
        :view-id="viewId"
        :objId="objId"
        :prefix="prefix"
        @viewIds="viewIds"
        @filterBoard="filterBoard"
        :style="{ height: tableHeight - 2 + 'px' }"
      >
      </Filter-panel>

      <!-- 标签列表 -->
      <LabelView
        class="LabelView"
        v-if="isLabelView"
        :objId="objId"
        :tabName="tabName"
        :getViewListDataParams="getViewListDataParams"
        @tagValue="tagValues"
        @gb="getMsgFormSon"
        :style="{ height: tableHeight - 2 + 'px' }"
      ></LabelView>

      <!-- 图表列表 -->
      <Chart
        v-if="isChart"
        class="chart"
        :ismodifyFalg="ismodifyFalg"
        :style="{ height: tableHeight - 2 + 'px' }"
        :view-id="viewId"
        :listDashboard="listDashboard"
        :loadingDashboard="loadingDashboard"
      ></Chart>

      <!-- 新增/修改记录 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :id="id"
        :objectApi="objectApi"
        :object-name="tabName"
        :realObjId="objId"
        @save="save"
      >
      </create-edit-obj>
      <!-- 添加标签 -->
      <div class="add_label">
        <add-label :ids="ids" :objId="objId" ref="addlabel"> </add-label>
      </div>
    </div>
    <!-- 导出至MailChimp -->
    <MailChimp
      ref="MailChimp"
      :listData="ids"
      :prefix="prefix"
      :viewId="viewId"
      :tabName="tabName"
      @MailChimpSelect="MailChimpSelect"
      :selectedList="selectedList"
      @refresh="refresh"
    ></MailChimp>
  </div>
</template>

<script>
import {
  ChooseView,
  SearchWrapper,
  TablePanel,
  Statistics,
  FilterPanel,
  LabelView,
} from '@/components/index'
import Chart from '@/views/commonObjects/components/chart.vue'
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import ViewButtons from '@/views/marketList/marketingChannel/component/button/viewButtons.vue'
import createEditObj from '@/views/commonObjects/components/create-edit-obj.vue'
import Kanban from '@/views/commonObjects/components/kanban.vue'
import shiftPlan from '@/views/commonObjects/components/shiftPlan.vue'
import { Message } from 'element-ui'
import {
  GetViewList,
  getReportListByObjId,
  GetViewKanbanDisplay
} from '../api'
import * as CommonObjApi from '../api'
import addLabel from '@/components/LabelView/addTag.vue'
import { GetTagsByRecordId, getObjectGetObjectInfo } from '@/views/marketList/api.js'
import { getDashboardListByObjId } from '@/views/marketList/api.js'
import Bus from '@/mixin/bus.js'
import * as types from '@/store/mutations-types'
import MailChimp from '@/components/MailChimp/index.vue'
export default {
  mixins:[TransferOwnerMixin],
  components: {
    ViewButtons,
    ChooseView,
    SearchWrapper,
    TablePanel,
    Statistics,
    FilterPanel,
    LabelView,
    Chart,
    Kanban,
    shiftPlan,
    addLabel,
    createEditObj,
    MailChimp,
  },
  data() {
    return {
      // btnList: [
      //   {
      //     label: this.$i18n.t('label.emailsync.button.cancel'),
      //     action: 'cancel',
      //     name: 'Batch cancel',
      //   },
      // ],
      // 跳转项目任务需要的参数
      projectTasksObjectApi: '',
      flag: '',
      rowIndex: '',
      apiname: '',
      ind: 0,
      alldataists: [],
      recordId: '', //主从记录ID
      editableCellValue: { value: '' },
      dataLists: [],
      showSearchTable: false,
      fieldId: '',
      checkList: [],
      zhucongfield: [],
      fieldlist: [], //表头信息
      isaddShow: true,
      stateone: '',
      validRule: true,
      state: '',
      selectNum: 0,
      optionList: {},
      pictLoading: false,
      lengths: 0,
      showKanban: false,
      showPlan: false,
      showBaseForm: false,
      showScreen: false, // 是否显示筛选器
      isLabelView: false, //显示标签
      isChart: false, //显示图表
      url: '',
      tabName: '',
      objectApi: this.$route.params.objectApi, // 对象api
      objectApis: [], //主从objectApis
      relatedApinames: [], //主从relatedApiname
      type: this.$route.params.type, // 视图类型
      objId: this.$route.params.objId, // 对象id
      prefix: this.$route.params.prefix, // 对象前缀
      tableSearchParams: {},
      inputWidth: '300px',
      tableHeight: '',
      tableAttr: null,
      // tableBtnList: {
      //   // 操作
      //   title: this.$i18n.t('label.operate'),
      //   buttonList: [
      //     createButton({
      //       // 编辑
      //       label: this.$i18n.t('label.modify'),
      //       action: 'edit',
      //     }),
      //     createButton({
      //       // 删除
      //       label: this.$i18n.t('label.delete'),
      //       action: 'del',
      //     }),
      //     createButton({
      //       // 更改所有人
      //       label: this.$i18n.t('label_tabpage_cownerz'),
      //       action: 'transferOwner',
      //     }),
      //   ],
      // },
      pageObj: {
        dataList: [],
      },
      dialogVisible: false,
      showBtns: [],
      showButtons: [
        'liebiaoshitu',
        'kanbanshitu',
        'fenpingshitu',
        'shezhi',
        'baobiao',
        'daochu',
        'dayin',
        'biaoqian',
        'tubiao',
        'shaixuan',
      ],
      showButtonsTest: ['shezhi', 'shaixuan'],
      viewName: '',
      viewId: this.$route.query.viewId,
      viewList: [], // 视图列表
      viewLength: 0,
      pageNum: 1, //页码默认为1
      pageSize: 50, // 每页展示数据条数
      totalSize: 0, // 总记录数
      noDataTip: false,
      sortField: '', // 排序字段
      sortFieldName: '',
      sortDir: 'asc', // 排序方式
      viewSelectedFieldList: [],
      viewUnselectedFieldList: [],
      searchKeyWord: '', // 搜索关键字
      conditionValues: '',
      tagIds: "", // 搜索标签
      isAllTag: '', // 是否同时满足所有标签
      isallsearch: 'false',
      myself: 'false',
      scrollId: 'false',
      getViewListDataParams: {},
      id: '', //记录id
      timer: '', //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: '',
      newViewId: '',
      labelArr: [],
      ids: '', // 表格选中数据id
      isQueryCount: false,
      viewInfo: {},
      selectedList: [], // 表格选中数据集合
      relatedItem: {}, // 事件类型的相关项
      currentUserInfo: {}, // 当前登录用户
      eventDate: {}, // 事件类型的日期
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      addable: '',
      reportList: [], // 对象对应的的报表列表
      loadingReport: false, // 报表列表的loading
      isTag: '',
      ismodifyFalg: '', //判断视图权限
      listDashboard: [], // 对象下的仪表板列表
      loadingDashboard: false, // 仪表板列表loading
      tabStyle: '', //对象图标
      kanBanData: null, //看板数据
      activeNames: null, //看板tab值
      exactSearchFlag: '', // 是否支持单字段搜索标识
      exactSearchFieldList: [], // 单字段搜索字段集合
      delContent: '', //删除提示语
      daochuUrl: '', //导出链接
      bool: null,
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.viewId = to.query.viewId
    this.type = to.params.type
    this.objectApi = to.params.objectApi
    this.objId = to.params.objId
    this.prefix = to.params.prefix
    this.totalSize = 0
    this.pageNum = 1
    this.pageObj.dataList = []
    this.sortField = ''
    this.sortFieldName = ''
    this.searchKeyWord = ''
    this.exactSearchFlag = ''
    this.$refs.searchWrapper.exactSearchFlag = ''
    this.lastRefresh = new Date()
    this.reportList = [] // 清空报表列表
    this.listDashboard = [] // 清空仪表板列表
    this.$refs.searchWrapper.search = ''
    if (this.$refs.tablePanel && this.$refs.tablePanel.checkedList) {
      this.$refs.tablePanel.checkedList = []
    }
    // 判断展示表格、看板或者计划轮班视图
    if (this.type === 'table') {
      this.showKanban = false
      this.showPlan = false
      if (this.$refs.pullRight) {
        this.$refs.pullRight.isBoardView = false
      }
    } else if (this.type === 'kanban') {
      this.showKanban = true
      this.showPlan = false
      this.exactSearchFlag = '0'
    } else if (this.type === 'plan') {
      this.showKanban = false
      this.showPlan = true
    }
    // this.showKanban = this.type === 'table' ? false : true

    // 同一对象下切换列表视图和看板视图时不刷新视图列表
    if (
      to.params.prefix !== from.params.prefix ||
      (this.type === 'table' && this.viewId === undefined)
    ) {
      this.getViewList(true)
    } else if (this.type === 'table') {
      this.getViewListRecently()
      this.getViewListHeader()
      this.getViewListData()
      this.getObjectGetObjectInfos()
    }
    next()
  },
  created() {
    // 判断展示表格、看板或者计划轮班视图
    if (this.type === 'table') {
      this.showKanban = false
      this.showPlan = false
    } else if (this.type === 'kanban') {
      this.showKanban = true
      this.showPlan = false
    } else if (this.type === 'plan') {
      this.showKanban = false
      this.showPlan = true
    }
    this.objId = this.$route.params.objId
    this.prefix = this.$route.params.prefix
    this.getViewList(true)
    // this.init();
  },
  mounted() {
    this.$store.commit('setObjIds', this.objId)
    this.tableHeight =
      document.getElementsByClassName('home_content')[0].clientHeight - 209
    Bus.$on('windowResize', this.ebFn)

    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date()
      let interval = currentTime - this.lastRefresh

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000))
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000))
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000))
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      hours = minutes > 30 ? hours + 1 : hours
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
              'label.login.access.day'
            )}${hours} ${this.$i18n.t('label_tabpage_hoursagoz')}`
            : `${days} ${this.$i18n.t('label_tabpage_Sdfez')}`
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          'label_tabpage_hoursagoz'
        )}`
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          'label_tabpage_minutesagoz'
        )}`
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t('vue_label_commonobjects_view_secondsbefore', {
              seconds: seconds,
            })
            : this.$i18n.t('label.notification.time1')
      }
    }, 1000)
  },
  methods: {
    ebFn (offsetHeight)  {
      this.dialogBodyHeight = `${offsetHeight - 240}px`
    },
    sendMile() {
      if (this.ids === '') {
        this.$message.error(
          this.$i18n.t('label.salescloud.teamrole.message.chooseatleastone')
        )
      } else {
        this.$router.push({
          name: 'sendMail',
          params: {
            id: this.$route.params.id,
            userIds: this.ids,
          },
        })
      }
    },
    // async activeChange(val) {
    //   // this.viewId=val

    //   // 特殊视图搜索条件改变时刷新表头
    //   if (
    //     this.viewId === '0' ||
    //     this.viewId === '1' ||
    //     this.viewId === '2' ||
    //     this.viewId === '3'
    //   ) {
    //     this.$router.push({
    //       path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
    //       query: {
    //         viewId: this.viewId,
    //       },
    //     })
    //     // this.refresh()
    //   } else {
    //     this.GetViewKanbanDisplays()
    //     this.refresh()

    //     // this.$refs.kanban.GetViewKanbanDisplays(" ",val)
    //   }

    // },
    getViewId(n) {
      this.newViewId = n
      if (n) {
        this.getNewViewList()
      }
    },
    // 点击报表，在选项卡菜单页面根据对象id获取报表列表
    async reportBoard() {
      // 若是当前报表列表为空，则请求接口获取该对象下的报表列表
      if (this.reportList.length === 0) {
        this.loadingReport = true
        let params = {
          id: this.objId, // 对象Id
        }
        let { data } = await getReportListByObjId(params)
        this.reportList = data
        this.loadingReport = false
      }
    },
    // 获取视图列表
    //当设置默认视图时，只刷新GetViewList，不刷新表格数据
    getViewList(viewFlag) {
      this.viewName = ''

      GetViewList({ objId: this.objId }).then((res) => {
        this.viewList = res.data
        this.viewList.map((item) => {
          // 获取列表视图/看板视图带过来的视图信息
          if (item.id === this.viewId) {
            item.isCurrent = true
            this.viewName = item.label
            this.viewInfo = item
            this.ismodifyFalg = item.ismodify
            this.business()
          } else {
            item.isCurrent = false
          }
        })

        if (this.viewName === '') {
          this.viewList.map((item) => {
            // 获取默认视图信息
            if (item.isdefault === '1') {
              this.viewName = item.label
              this.viewId = item.id
              this.viewInfo = item
              this.ismodifyFalg = item.ismodify
              this.business()
            } else {
              item.isCurrent = false
            }
          })
        }

        // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
        if (this.viewName === '') {
          this.viewList[0].isCurrent = true
          this.viewName = this.viewList[0].label
          this.viewId = this.viewList[0].id
          this.viewInfo = this.viewList[0]
          this.ismodifyFalg = this.viewList[0].ismodify
          this.business()
        }
        this.viewLength = this.viewList.length
        if (viewFlag) {
          this.getViewListRecently()
          this.getViewListHeader()
          this.getViewListData()
          this.getObjectGetObjectInfos()
        }
        // 如果为轮班视图，且为不可编辑的视图，展示列表
        if (
          this.type === 'plan' &&
          this.ismodifyFalg !== 'true' &&
          (this.viewId === '0' ||
            this.viewId === '1' ||
            this.viewId === '2' ||
            this.viewId === '3')
        ) {
          this.tableView()
        }
        //  document.title = this.$setTitle(
        //   `${this.viewName} | ${this.objectName}`
        // );
      })
    },
    /**
     * 获取视图列表
     * 新建视图保存后刷新视图列表，将新建视图设置为当前视图
     */
    // 获取视图列表
    getNewViewList() {
      this.viewName = ''

      GetViewList({
        objId: this.objId,
      }).then((res) => {
        this.viewList = res.data
        this.viewList.map((item) => {
          // 获取当前视图信息
          if (item.id === this.newViewId) {
            item.isCurrent = true
            this.viewName = item.label
            this.viewInfo = item
            this.ismodifyFalg = item.ismodify
          } else {
            item.isCurrent = false
          }
        })
        if (this.viewName === '') {
          this.viewList.map((item) => {
            // 获取默认视图信息
            if (item.isdefault === '1') {
              item.isCurrent = true
              this.viewName = item.label
              this.viewId = item.id
              this.ismodifyFalg = item.ismodify
              this.viewInfo = item
            } else {
              item.isCurrent = false
            }
          })
        } else {
          this.viewId = this.newViewId
        }

        // 若接口返回视图列表无默认视图，将第一个视图作为显示视图
        if (this.viewName === '') {
          this.viewList[0].isCurrent = true
          this.viewName = this.viewList[0].label
          this.viewId = this.viewList[0].id
          this.viewInfo = this.viewList[0]
          this.ismodifyFalg = this.viewList[0].ismodify
        }

        this.viewLength = this.viewList.length
        this.pageNum = 1
        this.getViewListRecently()
        this.getViewListHeader()
        this.getViewListData()
        this.changeView(this.viewInfo)
        this.getObjectGetObjectInfos()
      })
    },
    // 获取视图列表按钮权限
    getViewListRecently() {
      this.exactSearchFlag = ''
      this.exactSearchFieldList = []

      CommonObjApi.getViewListRecently({
        obj: this.prefix,
        viewId: this.viewId,
      }).then((res) => {
        document.title = this.$setTitle(
          `${res.data.viewName} | ${res.data.label}`
        )
        this.tabName = res.data.label
        this.tabStyle = res.data.tabStyle ? res.data.tabStyle : ''
        this.exactSearchFlag = res.data.exactSearchFlag || ''
        this.exactSearchFlag =
          this.type === 'kanban' ? '0' : this.exactSearchFlag
        this.exactSearchFieldList = res.data.exactSearchFieldList || []
        this.$store.commit(types.SET_TAB_NAME, this.tabName)

        if (this.prefix === '001') {
          this.tableBtnList.buttonList.splice(
            2,
            1,
            // eslint-disable-next-line no-undef
            createButton({
              // 更改所有人
              label: this.$i18n.t('label_tabpage_cownerz'),
              action: 'transferClientOwner',
            })
          )
        } else {
          this.tableBtnList.buttonList.splice(
            2,
            1,
            //转移所有人
            // eslint-disable-next-line no-undef
            createButton({
              label: this.$i18n.t('label_tabpage_cownerz'),
              action: 'transferOwner',
            })
          )
        }
        // 预计人工成本、实际人工成本不展示操作列
        if (
          this.$cookies.get('activeTabObjId') === 'cloudccPlanCost' ||
          this.$cookies.get('activeTabObjId') === 'cloudccRealCost'
        ) {
          this.tableBtnList.buttonList = []
        }
        // 是否启用查找统计
        this.isQueryCount = res.data.isQueryCount
        this.showBtns = res.data.listBtn.concat(res.data.toolsBtn)
      })
    },
    searchViewDetail() {
      if (
        (this.viewId === '0' ||
          this.viewId === '1' ||
          this.viewId === '2' ||
          this.viewId === '3') &&
        this.ismodifyFalg !== 'true'
      // eslint-disable-next-line no-empty
      ){
        
      }
        else {
        this.getFieldSetup()
      }
    },
    // 保存视图选中字段
    saveSelectedField(ids) {
      CommonObjApi.saveFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
        fieldIds: ids,
      }).then(() => {
        this.pageNum = 1
        this.getViewListHeader()
        this.getViewListData()
        this.$message.success(this.$i18n.t('label.search.saveok'))
      })
    },
    // 获取视图详细信息
    getFieldSetup() {
      this.viewSelectedFieldList = []
      this.viewUnselectedFieldList = []
      CommonObjApi.getFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
      }).then((res) => {
        this.viewSelectedFieldList = res.data.viewSelectedFieldList
        this.viewUnselectedFieldList = res.data.viewUnselectedFieldList
      })
    },
    // 获取视图列表表头
    getViewListHeader() {
      this.showScreen = false
      this.isLabelView = false
      this.isChart = false
      this.pictLoading = true
      this.tableAttr = []
      let params = {
        obj: this.prefix,
        viewId: this.viewId,
        tagIds: this.tagIds,
        isAllTag: this.isAllTag,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
      }
      CommonObjApi.getViewListHeader(params).then((res) => {
        // 打印数据存储到vuex
        this.$nextTick(() => {
          //解决第一次触发emit无效问题
          this.$store.state.printHeard = this.tableAttr
        })
        if (
          this.objId === 'cloudccPlanCost' ||
          this.objId === 'cloudccRealCost'
        ) {
          //项目管理中（实际人工成本和预计人工成本）隐藏新建按钮
          this.addable = false
        } else {
          this.addable = res.data.objServices.add
        }
        this.tableAttr = res.data.headLabel
        this.tableAttr.forEach((item, idx) => {
          // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          if (
            item.schemefieldName === 'name' ||
            item.schemefieldType === 'Y' ||
            item.schemefieldType === 'M' ||
            item.schemefieldType === 'MR' ||
            item.schemefieldName === 'subject' ||
            item.lookupObj === 'user' ||
            item.schemefieldName === 'whoid' ||
            item.schemefieldName === 'relateid'
          ) {
            this.$set(this.tableAttr[idx], 'click', 'detail')
          }

          // 项目管理-实际工作清单点击日期跳转详情
          if (this.$cookies.get('activeTabObjId') === 'projectworklist') {
            if (item.schemefieldType === 'D') {
              this.$set(this.tableAttr[idx], 'click', 'detail')
            }
          }
          this.$set(this.tableAttr[idx], 'sortByThis', false)
          this.$set(this.tableAttr[idx], 'sortDir', 'asc')
          this.$set(this.tableAttr[idx], 'fixed', false)
          this.$set(this.tableAttr[idx], 'locked', false)
        })

        this.$refs.tablePanel &&
          this.$refs.tablePanel.changeTableAttr(this.tableAttr)
        setTimeout(() => {
          this.pictLoading = false
        }, 1000)
      })
    },
    change(val) {
      this.pictLoading = val
      // this.selectedList=[]
      setTimeout(() => {
        this.pictLoading = false
      }, 1000)
    },
    // 刷新
    refresh() {
      // 
      this.$refs.kanban &&
        this.$refs.kanban.GetViewKanbanDisplays(' ', this.viewId)
      this.sortField = ''
      this.sortFieldName = ''
      this.lastRefresh = new Date()
      // 重置列表信息
      this.pageObj.dataList = []
      this.pageNum = 1
      this.tagIds = ""
      // 重置列表选中统计数据
      if (this.$refs.tablePanel) {
        this.$refs.tablePanel.checkedList = []
      }
      this.getViewListHeader()
      this.getViewListData()
    },
    // 批量删除数据后刷新
    refreshAfterBatchDelete() {
      // 重置列表信息
      this.pageObj.dataList = []
      this.pageNum = 1
      // 重置列表选中统计数据
      if (this.$refs.tablePanel) {
        this.$refs.tablePanel.checkedList = []
      }
      this.getViewListData()
    },
    // 搜索条件改变
    changeKeyWord(val) {
      if (this.$route.params.type === 'kanban') {
        this.GetViewKanbanDisplays(val)
      } else if (this.$route.params.type === 'plan') {
        // 如果是轮班视图
        this.$refs.shiftPlan.handleSearch(val)
      } else {
        this.pageNum = 1
        this.searchKeyWord = val
        // 特殊视图搜索条件改变时刷新表头
        if (
          this.viewId === '0' ||
          this.viewId === '1' ||
          this.viewId === '2' ||
          this.viewId === '3'
        ) {
          this.getViewListHeader()
        }
        // 清空列表选中数据
        if (this.$refs.tablePanel && this.$refs.tablePanel.checkedList) {
          this.$refs.tablePanel.checkedList = []
        }
        this.getViewListData()
      }
    },
    // 获取视图数据
    getViewListData() {
      if (this.viewId) {
        /**
         * 参数说明
         * 参数名	必选	类型	说明
         * obj	是	string	对象前缀
         * viewId	是	string	视图Id
         * pageNum	是	string	页数
         * pageSize	是	string	每页数量
         * sortField	是	string	排序字段apiname
         * sortDir	是	string	排序类型(desc[降序]/asc[升序])
         * searchKeyWord	是	string	搜索关键字
         * conditionValues	是	string	高级搜索条件
         * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
         * recordnums	是	string	每页查询默认数目
         * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
         * isAllTag	否	string	是否同时满足所有选中标签（true/false）
         * campaignid	否	string	市场活动成员是否只查询现有成员
         * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
         * myself	否	string	全局搜索是否只查询所有人是自己的记录
         * scrollId	否	string	全文检索查询返回值，下一页查询回传
         * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
         */

        this.noDataTip = false
        this.pictLoading = true
        this.getViewListDataParams = {
          obj: this.prefix,
          viewId: this.viewId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sortField: this.sortField,
          sortDir: this.sortField === '' ? '' : this.sortDir,
          searchKeyWord: this.searchKeyWord,
          conditionValues: this.conditionValues,
          ischangenum: 'false',
          recordnums: this.pageSize,
          tagIds: this.tagIds,
          isAllTag: this.isAllTag,
          campaignid: 'false',
          isallsearch: this.isallsearch,
          myself: this.myself,
          scrollId: this.scrollId,
          type: '',
          exactSearchFlag: this.exactSearchFlag,
        }


        
        CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
          this.daochuUrl = res.data.exportUrl
          if (res.result && res.returnCode === '1') {
            // 请求第一页数据时先清空列表
            if (this.getViewListDataParams.pageNum === 1) {
              this.pageObj.dataList = []
            }
            this.totalSize = res.data.totalSize

            if (res.data.list.length === 0 && this.pageNum === 1) {
              this.pageObj.dataList = []
              this.pageNum++
            } else if (
              this.pageObj.dataList.length === 0 ||
              this.pageNum === 1
            ) {
              this.pageObj.dataList = res.data.list
              this.pageNum++
            } else {
              let checkedList = this.$refs.tablePanel.getCheckedList()
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.list,
              ]

              this.pageObj.dataList.forEach((element) => {
                checkedList.forEach((item) => {
                  if (item.id === element.id) {
                    element.checked = true
                  }
                })
              })

              this.pageNum++
              this.$refs.tablePanel.dataList = this.pageObj.dataList
            }
            // 判断是否有更多数据
            if (res.data.haveMore === 'false') {
              this.noDataTip = true
            }

            // this.$refs.tablePanel.$refs.tablePanel &&
            // this.$refs.tablePanel.$refs.tablePanel.doLayout();
            setTimeout(() => {
              this.pictLoading = false
            }, 1000)
            setTimeout(() => {
              this.$refs.tablePanel &&
                this.$refs.tablePanel.$refs.tablePanel &&
                this.$refs.tablePanel.$refs.tablePanel.doLayout()
            }, 2000)
            this.lastRefresh = new Date()
            this.pageObj.totalSize = res.data.totalSize
          } else {
            this.$message.error(res.returnInfo)
          }
        })
      }
    },
    // 切换视图
    changeView(view) {
      this.searchKeyWord = ''
      this.exactSearchFlag = ''
      this.exactSearchFieldList = []
      this.$refs.searchWrapper.searchKeyWord = ''
      if (this.$route.params.objectApi === 'Opportunity') {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'baobiao',
          'biaoqian',
          'tubiao',
          'shaixuan',
          'xiaoshou',
        ]
      } else {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'biaoqian',
          'tubiao',
          'shaixuan',
        ]
      }

      this.viewList.map((item) => {
        // 获取默认视图信息
        if (item.id === view.id) {
          this.viewName = item.label
          this.viewId = item.id
          item.isCurrent = true
          this.ismodifyFalg = item.ismodify
          this.viewInfo = item
          this.business()
        } else {
          item.isCurrent = false
        }
      })

      // 如果为轮班视图，且为不可编辑的视图，展示列表
      if (
        this.type === 'plan' &&
        this.ismodifyFalg !== 'true' &&
        (this.viewId === '0' ||
          this.viewId === '1' ||
          this.viewId === '2' ||
          this.viewId === '3')
      ) {
        this.tableView()
      } else if (this.type === 'plan') {
        this.$refs.shiftPlan.handleView()
      }
      // 刷新视图列表
      this.refresh()
      this.getViewListRecently()
      this.getObjectGetObjectInfos()
    },
    // 新建
    add() {
      this.id = ''
      this.$refs.createEditObj.add()
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      this.currentUserInfo = {
        id: localStorage.getItem('userId'),
        name: localStorage.getItem('username'),
      }
    },
    // 获取当前系统时间的下一个整点以及整点之后一个小时的时间
    getHour() {
      let date = new Date()
      date.setHours(date.getHours() + 1)
      let y = date.getFullYear()
      let m =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      let startTime = y + '-' + m + '-' + d + ' ' + h + ':00:00'
      function getNextHour(intPoint) {
        let dd = new Date(intPoint)
        let Y = dd.getFullYear()
        let M =
          dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
        let D = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
        let H =
          dd.getHours() + 1 < 10 ? '0' + (dd.getHours() + 1) : dd.getHours() + 1
        return Y + '-' + M + '-' + D + ' ' + H + ':00:00'
      }
      return { start: startTime, end: getNextHour(startTime) }
    },
    // 编辑
    edit(row) {
      this.id = row.data.id
      this.$nextTick(() => {
        this.$refs.createEditObj.edit(row)
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants

      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    //获取更改所有人页面信息
    getchangeowner() {
      CommonObjApi.getChangeOwner().then(() => { })
    },
    handleSelect() { },
    handleIconClick() { },
    closeSearchTable() {
      this.showSearchTable = false
    },
    // 新增/修改保存
    save() {
      this.pageNum = 1
      this.getViewListData()
    },
    //取消发送邮箱
    cancelMail() {
      this.$router.go(-1)
    },
    // 弹窗关闭
    cancel() {
      this.dialogAttr.isShow = false
      this.zhucongfield = []
      this.alldataists = []
      this.dataLists = []
      this.row = ''
    },
    // 删除
    del(row) {
      //项目管理中项目任务删除子任务时修改objectApi
      if (
        this.$route.path.substring(this.$route.path.length - 3) == 'p03' &&
        row.data.their_task
      ) {
        this.objectApi = 'cloudccSubtask'
      }
      if (this.objectApi === 'CloudccProject') {
        //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t('label.projectManagement.delete.item')
      } else if (this.objectApi === 'cloudccMilestone') {
        // 删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          'label.projectManagement.delete.milestone'
        )
      } else if (this.objectApi === 'CloudccProblem') {
        //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          'label.projectManagement.delete.question'
        )
      } else if (this.objectApi === 'cloudccTask') {
        // '删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？'
        this.delContent = this.$i18n.t('label.projectManagement.delete.task')
      } else if (this.objectApi === 'cloudccSubtask') {
        // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
        this.delContent = this.$i18n.t(
          'label.projectManagement.delete.subtasks'
        )
      } else {
        this.delContent = this.$i18n.t('label.weixin.confirm.delete')
      }
      this.id = row.data.id
      this.dialogVisible = true
    },
    // 确认删除数据
    confirmDel() {
      CommonObjApi.del({ id: this.id, objectApi: this.objectApi }).then(
        () => {
          this.pageNum = 1
          this.dialogVisible = false
          this.$message.success(this.$i18n.t('label.ems.delete.success'))
          this.getViewListData()
        }
      )
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    // 详情
    detail(row) {
      // 查找字段跳到对应详情页
      // 事件及任务类型名称和相关项跳转
      if (
        row.field.schemefieldType === 'Y' ||
        row.field.lookupObj === 'user' ||
        row.field.schemefieldName === 'relateid'
      ) {
        CommonObjApi.getPermissionById({
          id: row.data[row.field.schemefieldName],
        }).then((res) => {
          if (res.data.isQuery) {
            // this.$router.push({
            //   path: `/commonObjects/detail/${row.data[row.field.schemefieldName]}/DETAIL`,
            //   query: { objId: this.objId }
            // });
          } else {
            this.$message.warning(
              // "抱歉，您无权查看该条数据，请联系系统管理员。"
              this.$i18n.t('2018UI_Homepage_DataNoAccess_Info')
            )
            return false
          }
        })
      } else {
        // this.$router.push({
        //   path: `/commonObjects/detail/${row.data.id}/DETAIL`,
        //   query: {
        //     viewId: this.viewId,
        //     objId: this.objId
        //   },
        // });
      }
    },
    // 列表视图
    tableView() {
      if (this.$route.path.indexOf('/commonObjects/views/table') === -1) {
        this.$router.push({
          path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: {
            viewId: this.viewId,
          },
        })
      }
    },
    // 看板视图
    boardView() {
      // 项目任务跳转百分比页面
      // if (this.projectTasksObjectApi === 'cloudccTask') {
      //   let tasjId = 'ffe20201207task09'
      //   localStorage.setItem('key', tasjId)
      //   this.$router.push({
      //     path: `/projectTasks`,
      //   })
      // } else
      if (this.$route.path.indexOf('/commonObjects/views/kanban') === -1) {
        this.$router.push({
          path: `/commonObjects/views/kanban/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: {
            viewId: this.viewId,
          },
        })
      }
    },
    // 轮班计划视图
    planView() {
      if (this.$route.path.indexOf('/commonObjects/views/plan') === -1) {
        this.$router.push({
          path: `/commonObjects/views/plan/${this.objectApi}/${this.objId}/${this.prefix}`,
          query: {
            viewId: this.viewId,
          },
        })
        this.$nextTick(() => {
          this.$refs.shiftPlan.renderPage()
        })
      }
    },
    // 分屏视图
    multiScreen() {
      this.$router.push({
        path: `/commonObjects/multi-screen/${this.objectApi}/${this.objId}/${this.prefix}`,
        query: {
          sortFieldName: this.sortFieldName,
          sortField: this.sortField,
          sortDir: this.sortDir,
          viewId: this.viewId,
        },
      })
    },
    // 创建视图
    createView() {
      this.$router.push({
        path: '/newView',
      })
    },
    // 通用列表
    customTable() {
      this.$router.push({
        path: '/commonObjects',
      })
    },
    // 内联编辑
    handleChange() { },
    // 自动换行
    wordWrap() {
      //
    },
    // 锁定
    locked(field) {
      let fiexdList = this.tableAttr.filter((item) => {
        return item.fixed === true
      })

      if (fiexdList.length > 2) {
        Message.warning(
          this.$i18n.t('vue_label_commonobjects_view_fixed_at_most')
        )
        this.tableAttr.forEach((item) => {
          if (item.id === field.id) {
            item.fixed = false
            item.locked = false
          }
        })
      } else {
        this.tableAttr.forEach((item) => {
          if (item.id === field.id) {
            item.fixed = !item.fixed
          }
        })
      }

      this.$forceUpdate()
      this.$refs.tablePanel.changeTableAttr(this.tableAttr)
    },
    // 修改排序字段
    changeSortFiled(field) {
      this.pageNum = 1
      this.skippageNum = 1
      this.pageObj.dataList = []

      // 若排序字段不变，改变排序方式
      if (this.sortField === field.apiname) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      } else {
        // 否则重置排序字段和排序方式
        this.clearSort()
        this.sortDir = 'asc'
        this.sortField = field.apiname
        this.sortFieldName = field.nameLabel
      }
      // 刷新视图
      this.getViewListData()
      this.$forceUpdate()
      this.$refs.tablePanel.changeTableAttr(this.tableAttr)
    },
    // 清除排序
    clearSort() {
      this.tableAttr.forEach((item) => {
        item.sortByThis = false
      })
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getViewListData()
      }
    },
    // 筛选器：点击按钮及关闭的回调
    filterBoard() {
      this.showScreen = !this.showScreen
      this.isLabelView = false
      this.isChart = false
    },
    //标签列表
    tagManage() {
      this.isLabelView = !this.isLabelView
      this.isChart = false
      this.showScreen = false
    },
    //图表列表,根据对象id获取图表内的仪表板列表
    async chartsBoard() {
      this.isChart = !this.isChart
      this.showScreen = false
      this.isLabelView = false
      // 判断是否在点击图表按钮展开
      if (this.isChart) {
        // 判断仪表板列表是否有数据
        if (this.listDashboard.length === 0) {
          this.loadingDashboard = true
          let params = {
            objId: this.objId, // 对象id
          }
          let data = await getDashboardListByObjId(params)
          if (data.result) {
            this.listDashboard = data.data.listDashboard
          } else {
            this.listDashboard = []
          }
          this.loadingDashboard = false
        }
      }
    },
    //打印
    print() {
      window.open(
        `#/print-table/${this.objId}/${this.prefix}/${this.objectApi}/${this.viewId}?tagIds=${this.tagIds}`,
        '_parent - URL',
        'height=700, width=1210, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no'
      )
    },
    getMsgFormSon(data) {
      this.isLabelView = data
    },
    //筛选调用数据刷新
    recall() {
      this.showScreen = false
      if (this.$refs.shiftPlan) {
        this.$refs.shiftPlan.handleSearch()
      }
      this.pageNum = 1
      this.getViewListData()
    },
    //设置视图刷新数据
    refreshView() {
      this.getNewViewList()
    },
    //标签页向父组件传值
    tagValues(tagValue,allTag) {
      this.isAllTag = allTag;
      this.tagIds = tagValue
      this.skippageNum = 1
      this.getViewListData()
    },
    actives() {
      this.$refs.kanban.open()
    },
    addTag() {
      if (this.selectedList.length > 200 || this.lengths > 200) {
        this.$message.info(
          this.$i18n.t('vue_label_commonobjects_view_up_to_data')
        )
      } else {
        if (this.ids === '') {
          this.$message.info(this.$i18n.t('lable.opppdt.selPdt.leastone'))
        } else {
          this.$refs.addlabel.isShows()
        }
      }
    },
    // 获取新添加的标签
    determineFun() {
      this.$refs.addlabel.isShows()
      this.getTagsByRecordId()
    },
    closeAddBtn() {
      this.$refs.addlabel.isShows()
    },
    getTagsByRecordId() {
      GetTagsByRecordId({
        objId: this.$route.params.objId,
        recordId: this.id,
      }).then((res) => {
        this.labelArr = res.data
      })
    },
    quxiao() {
      this.isChart = false
    },
    // 表格选中数据
    onchange(val) {
      this.selectedList = val

      if (this.bool == false) {
        this.selectedList = this.$store.state.checkedNum
      }
      let ids = ''
      for (var i = 0; i < val.length; i++) {
        ids += val[i].id + ','
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1)
      }
      this.ids = ids
    },
    chat(val) {
      this.ids = val
    },
    isChat(val) {
      if (val === true) {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'tubiao',
          'shaixuan',
        ]
      } else {
        if (this.viewInfo.ismodify === 'false') {
          this.showButtons = [
            'liebiaoshitu',
            'fenpingshitu',
            'shezhi',
            'baobiao',
            'daochu',
            'dayin',
            'biaoqian',
          ]
        } else {
          this.showButtons = [
            'liebiaoshitu',
            'kanbanshitu',
            'fenpingshitu',
            'shezhi',
            'baobiao',
            'daochu',
            'dayin',
            'biaoqian',
            'tubiao',
            'shaixuan',
          ]
        }
      }
    },
    longitude(val) {
      this.lengths = val
    },
    // kanban () {
    //   this.showButtons = ['liebiaoshitu', 'kanbanshitu', 'fenpingshitu', 'shezhi', 'baobiao', 'daochu', 'dayin',
    //     'biaoqian', 'tubiao', 'shaixuan'
    //   ]
    // },
    business() {
      //判断视图id是否为三个没有任何权限的视图
      if (this.viewInfo.ismodify === 'false') {
        if (
          this.$route.params.objectApi === 'Opportunity' &&
          this.$route.params.type !== 'kanban'
        ) {
          //判断当前对象是否为业务机会且类型不为看板时返回的标签
          this.showButtons = [
            'liebiaoshitu',
            'fenpingshitu',
            'shezhi',
            'baobiao',
            'daochu',
            'dayin',
            'biaoqian',
            'xiaoshou',
          ]
        } else {
          this.showButtons = [
            'liebiaoshitu',
            'fenpingshitu',
            'shezhi',
            'baobiao',
            'daochu',
            'dayin',
            'biaoqian',
          ]
        }
        return
      } else {
        //判断视图id不为三个没有任何权限的视图且是业务机会的时候所返回的标签
        if (
          this.$route.params.objectApi === 'Opportunity' &&
          this.$route.params.type !== 'kanban'
        ) {
          this.showButtons = [
            'liebiaoshitu',
            'kanbanshitu',
            'fenpingshitu',
            'shezhi',
            'baobiao',
            'daochu',
            'dayin',
            'biaoqian',
            'tubiao',
            'shaixuan',
            'xiaoshou',
          ]
        } else {
          this.showButtons = [
            'liebiaoshitu',
            'kanbanshitu',
            'fenpingshitu',
            'shezhi',
            'baobiao',
            'daochu',
            'dayin',
            'biaoqian',
            'tubiao',
            'shaixuan',
          ]
        }
      }
      //当对象为业务机会且类型为看板时返回的标签
      if (
        this.$route.params.objectApi === 'Opportunity' &&
        this.$route.params.type === 'kanban'
      ) {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'tubiao',
          'shaixuan',
          'xiaoshou',
        ]
      } else if (
        this.$route.params.objectApi === 'Opportunity' &&
        this.$route.params.type !== 'kanban'
      ) {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'biaoqian',
          'tubiao',
          'shaixuan',
          'xiaoshou',
        ]
      } else if (this.$route.params.type === 'kanban') {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          // "biaoqian",
          'tubiao',
          'shaixuan',
        ]
      } else {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'biaoqian',
          'tubiao',
          'shaixuan',
        ]
      }
    },

    // async xiaoshou() {
    // this.$router.push(`/commonObjects/hopper`)
    // let params = {
    //   obj: this.getViewListDataParams.obj,
    //   viewId: this.$route.params.viewId,
    // };
    // let res = await getViewListRecently(params);
    //
    // if (res.result) {
    //
    //   window.location.href = res.data.toolsBtn[0].url;
    // }
    // },
    viewIds(val) {
      this.newViewId = val
      this.getNewViewList()
    },
    recalles() {
      this.pageNum = 1
      this.recall()
    },
    async getObjectGetObjectInfos() {
      let params = {
        id: this.objId,
      }
      let res = await getObjectGetObjectInfo(params)
      if (res.result) {
        this.isTag = res.data.istag
      }
    },
    //获取看板视图展示数据
    async GetViewKanbanDisplays(val) {
      let params = {
        viewId: this.viewId,
        searchKeyWord: val,
        recordType: this.activeNames,
      }
      let res = await GetViewKanbanDisplay(params)
      if (res.result) {
        this.kanBanData = res.data
      }
    },
    //看板tab值
    activeName(val) {
      this.activeNames = val
    },
    //打开MailChimp弹框
    mailchimp() {
      if (this.selectedList.length === 0) {
        this.$message.error(
          this.$i18n.t('label.integrations.mailchimp.massage1')
        )
      } else {
        this.$refs.MailChimp.open()
      }
    },
    //全选列表数据
    MailChimpSelect(val) {
      this.bool = val
      if (val === true) {
        this.$refs.tablePanel.$refs.tablePanel.toggleAllSelection()
      } else {
        this.$refs.tablePanel.$refs.tablePanel.clearSelection()
        this.selectedList = this.$store.state.checkedNum
        this.$store.state.checkedNum.forEach((item) => {
          this.$refs.tablePanel.$refs.tablePanel.toggleRowSelection(item, true)
        })
      }
    },
  },
  watch: {
    type(val) {
      if (val === 'table') {
        this.lengths = 0
        this.isChat(false)
      } else if (val === 'Opportunity') {
        this.showButtons = [
          'liebiaoshitu',
          'kanbanshitu',
          'fenpingshitu',
          'shezhi',
          'baobiao',
          'daochu',
          'dayin',
          'biaoqian',
          'tubiao',
          'shaixuan',
          'xiaoshou',
        ]
      }
    },
    tabName() {
      // 列表表头日历更改为事件
      if (this.objectApi === 'Event') {
        this.tabName = this.$i18n.t('label.event')
      }
    },

    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.projectTasksObjectApi = route.params.objectApi
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    Bus.$off('windowResize', this.ebFn)
    localStorage.setItem('relevantObjectLevel', 0)
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>

<style lang="scss" scoped>
.buttonWrapper {
  position: relative;

  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }
  }

  .createBtn {
    height: 28px;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    margin-left: 10px;
    li:last-child {
      border-right: 0;
      span:first-child {
        // border-right: 1px solid #dedcda;
      }
    }
    li {
      font-size: 12px;
      cursor: pointer;
      border-right: 1px solid #dedcda;
      line-height: 29px;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
        &:hover {
          color: #006dcc;
        }
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        font-size: 18px;
        color: #888888;
        padding: 3px 5px 0 5px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      border: 1px solid #c8c6c6;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      margin-top: -15px;
    }

    .in-left {
      margin-left: 10px;
    }

    .in-right {
      margin-right: 10px;
    }
  }
}
.top-btns {
  border: 1px solid #dedcda;
  border-radius: 3px;
  height: 30px;
  line-height: 27px;
  background: #ffff;
  margin-left: 10px;
  li:last-child {
    border-right: 0;
    span:first-child {
      // border-right: 1px solid #dedcda;
    }
  }
  li {
    font-size: 12px;
    cursor: pointer;
    border-right: 1px solid #dedcda;
    line-height: 29px;
    .btnName {
      padding: 0 12px;
      height: 100%;
      display: inline-block;
      cursor: pointer;
      color: #006dcc;
      &:hover {
        color: #006dcc;
      }
    }

    .dividingLine {
      font-size: 16px;
      color: #c8c6c6;
    }

    .icon {
      font-size: 18px;
      color: #888888;
      padding: 3px 5px 0 5px;
    }
  }
}
::v-deep .el-table--border {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-table__fixed::before {
  height: 0;
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  background: #f0f0f0;
}

.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #f5f5f5;
  }
}

/* 筛选器位置 */
.screen {
  // height: 88%;
  height: calc(100% - 116px);
  position: absolute;
  right: 11px;
  top: 105px;
  z-index: 99;
}

/* 标签列表位置 */
.LabelView {
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 111px;
  z-index: 99;
}

/* 图表位置 */
.chart {
  width: 300px;
  height: calc(100% - 116px);
  // height: 88%;
  position: absolute;
  right: 11px;
  top: 105px;
  z-index: 99;
}

.topContent {
  width: 100%;
  // background: #fff;
}

.left-content {
  width: 23%;
  padding: 10px 10px 0;

  .choose-view {
    width: 100%;
  }
}

.right-content {
  width: 77%;
  padding: 13px 10px 0 0;

  .searchWrapper {
    margin-right: 10px;
    height: 41px;
  }
}

.add_label {
  position: absolute;
  top: 57px;
  left: 35%;
}
.selectnum {
  height: 12px;
  font-size: 12px;
  margin-top: -20px;

  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
/*批量新增css*/
.tank {
  height: 480px;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}

.listulFir {
  height: 40px;
  min-width: 100%;
  border: 1px solid rgba(190, 225, 255, 1);
  border-left: none;
  display: inline-flex;
  background: rgba(222, 240, 253, 1);
}
.listulSec:nth-of-type(2n) {
  height: 40px;
  display: inline-flex;
  background: rgba(240, 248, 255, 1);
  min-width: 100%;
}
.listulSec:nth-of-type(2n + 1) {
  height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
}
.firstLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  min-width: 80px;
  text-align: center;
}
.secondLi {
  border-right: 1px solid rgba(190, 225, 255, 1);
  text-align: center;
  width: 180px;
  line-height: 40px;
}
::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.tianjia {
  margin-top: 20px;
  cursor: pointer;
  width: 32px;
  margin-bottom: 10px;
  margin-left: 16px;
}
ul {
  margin-bottom: 0;
}
</style>
