<!-- 列表上方按钮 -->
<template>
  <div class="viewButtons">
    <!-- 视图操作按钮 -->
    <div class="line-two-btn pull-right">
      <el-button-group style="margin-right: 10px">
        <el-dropdown
          @command="itemBtn"
          @visible-change="visibleBtn"
          trigger="hover"
          v-if="!multiScreen"
        >
          <el-tooltip
            popper-class="my-tooltip"
            class="item el-dropdown-link"
            effect="dark"
            :content="$t('label.view.manage')"
            placement="top"
            :open-delay="500"
          >
            <el-button
              size="mini"
              class="btn in-left shezhi"
              @focus.native="shezhishow = false"
              @blur.native="shezhishow = true"
            >
              <svg class="icon" aria-hidden="true">
                <use
                  :href="shezhishow ? '#icon-shezhi' : '#icon-shezhi1'"
                ></use>
              </svg>
            </el-button>
          </el-tooltip>
          <el-dropdown-menu slot="dropdown" hide-on-click="false">
            <!-- <p>视图管理</p> -->
            <el-dropdown-item
              :command="$t('label.new')"
              :disabled="isNewDisabled"
              >{{ $t("label.new") }}</el-dropdown-item
            >
            <el-dropdown-item
              :command="$t('label.clone')"
              :disabled="isDisabled"
              >{{ $t("label.clone") }}</el-dropdown-item
            >
            <el-dropdown-item
              :command="$t('label.modify')"
              :disabled="isDisabled"
              >{{ $t("label.modify") }}</el-dropdown-item
            >
            <el-dropdown-item
              :command="$t('label.delete')"
              :disabled="isDisabled"
              >{{ $t("label.delete") }}</el-dropdown-item
            >
            <li
              @click="itemBtn($t('label.sort'))"
              :class="['el-dropdown-menu__item', sortFlag ? 'active' : '']"
            >
              {{ $t("label.sort") }}
            </li>

            <!-- 重置列宽 -->
            <el-dropdown-item
              v-show="this.$route.params.type !== 'kanban'"
              :command="$t('vue_label_commonobjects_view_reset_width')"
              >{{
                $t("vue_label_commonobjects_view_reset_width")
              }}</el-dropdown-item
            >
            <!-- 看板视图设置 -->
            <el-dropdown-item
              v-if="viewInfos.ismodify === 'true' ? true : false"
              :command="$t('vue_label_commonobjects_view_settings')"
              v-show="this.$route.params.type === 'kanban'"
              :disabled="isDisabled"
              >{{
                $t("vue_label_commonobjects_view_settings")
              }}</el-dropdown-item
            >
            <div class="set_wrap" v-show="this.sortFlag">
              <!-- <p>视图排序</p> -->
              <p>{{ $t("label.view.sortview") }}</p>
              <div class="sort_box">
                <sortDrop
                  ref="sort"
                  v-on:sortFun="sortFun"
                  :objId="objId"
                  @getOriginFields="getOriginFields"
                ></sortDrop>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </el-button-group>
      <el-button-group v-if="!multiScreen" style="margin-left: 10px">
        <!-- 筛选器 -->
        <el-tooltip
          popper-class="my-tooltip"
          class="item"
          effect="dark"
          :content="$t('label.weixin.report.filters')"
          placement="top-start"
          :open-delay="500"
        >
          <el-button
            size="mini"
            class="btn group-btn shaixuanActive"
            @click="handleClick('filterBoard')"
          >
            <svg class="icon" aria-hidden="true">
              <use href="#icon-shaixuan1"></use>
            </svg>
          </el-button>
        </el-tooltip>
      </el-button-group>
      <!-- 视图弹窗 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogFormVisible"
        class="newBox"
        center
        :close-on-click-modal="false"
      >
        <div style="text-align: center">
          <i
            v-if="loading"
            class="el-icon-loading"
            style="font-size: 20px; margin-top: 200px"
          >
          </i>
        </div>
        <el-row v-if="!loading">
          <el-form
            :rules="rules"
            ref="objForms"
            :model="objForm"
            :label-width="formLabelWidth"
            label-position="left"
            size="small"
          >
            <!-- <el-form-item label="名称"  -->
            <el-form-item
              :label="$t('label.name')"
              prop="label"
              class="label_name"
            >
              <el-input
                v-model="objForm.label"
                autocomplete="off"
                class="input_name"
              ></el-input>
              <el-checkbox v-model="isUsedForMobile" class="mobileFilter">
                <!-- 应用于移动端 -->
                {{ $t("label.tab.profile.ismobile") }}
              </el-checkbox>
            </el-form-item>
            <!-- 编辑视图 -->
            <el-col
              :span="12"
              v-show="this.title === $t('label.view.introduce')"
              style="margin-top: 5px"
            >
              <!-- <el-form-item label="创建人" -->
              <el-form-item
                :label="$t('label.createby')"
                style="margin-left: 12px"
              >
                <span>
                  {{ objForm.createbyidccname }}
                  {{ objForm.createdate }}
                </span>
              </el-form-item>
            </el-col>
            <!-- 编辑视图 -->
            <el-col
              :span="12"
              v-show="this.title === $t('label.view.introduce')"
              style="margin-top: 5px"
              class="label_w"
            >
              <!-- <el-form-item label="最后修改人"> -->
              <el-form-item :label="$t('label.lastmodifyby')">
                <span
                  >{{ objForm.lastmodifybyidccname }}
                  {{ objForm.lastmodifydate }}
                </span>
              </el-form-item>
            </el-col>
            <!--  <el-form-item label="筛选条件" -->
            <el-col :span="24">
              <el-form-item
                :label="$t('label.filterconditions')"
                style="margin-left: 12px"
              >
                <span class="setSize" style="float: left">
                  <el-popover
                    width="540"
                    trigger="hover"
                    placement="bottom-start"
                  >
                    <div>
                      <div style="word-break: break-word">
                        1、
                        <!-- 记录类型字段，选项列表字段和选项列表（多选）字段，建议最多输入10个关键字，用英文逗号隔开实现或关系，如：“北京,上海,广州”。 -->
                        {{ $t("label_tabpage_fieldoptions") }}
                        <br />2、
                        <!-- 日期字段等于时可以使用“昨天”，“今天”，“明天”，“上个月”，“本月”，“下个月”，“上星期”，“本星期”，“下星期”或 “YYYY-MM-DD、MM/DD/YYYY”格式。 -->
                        {{ $t("label.view.desc") }}
                        <br />3、
                        <!-- 当不启用高级筛选时，各个筛选条件之间是“与”的关系，-->
                        {{ $t("label.hight.tip") }}
                        <span v-html="$t('vue_label_filternotice1')"></span>
                        {{ $t("label.field.for.example") }}
                      </div>
                    </div>
                    <svg
                      class="icon tipsBox"
                      aria-hidden="true"
                      slot="reference"
                    >
                      <use href="#icon-explain"></use>
                    </svg>
                  </el-popover>
                  <!-- 所有人 -->
                  {{ $t("label.owner") }}
                </span>
                <el-radio-group
                  v-model="objForm.screeRadio"
                  @change="queueBtn"
                  style="
                    display: flex;
                    float: left;
                    line-height: 36px;
                    height: 36px;
                    align-items: center;
                  "
                >
                  <el-radio label="0">
                    <!-- 全部 -->
                    {{ $t("label.quickbooks.view.all") }}
                  </el-radio>
                  <el-radio label="1">
                    <!-- 我的 -->
                    {{ $t("label.my") }}
                  </el-radio>
                  <el-radio label="2" v-if="isQueue">
                    {{ $t("label_tabpage_queue") }}
                    <!-- 队列 -->
                  </el-radio>
                  <el-select
                    v-model="queryQueueValue"
                    :placeholder="$t('label.report.select.condition')"
                    v-if="isQueueSelect"
                    style="margin-top: -8px; margin-left: 7px; width: 170px"
                  >
                    <el-option
                      v-for="item in queryQueueList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div
                class="screen_s"
                v-for="(ev, index) in objForm.list"
                :key="index"
              >
                <el-form-item>
                  <span style="width: 21px">{{ index + 1 }}</span>
                  <!-- placeholder="选择字段" -->
                  <el-select
                    v-model="ev.fieldId"
                    :placeholder="$t('label.selectfield')"
                    class="select_one"
                    filterable
                    clearable
                    @change="selectChange($event, index)"
                  >
                    <el-option
                      v-for="item in optionst_one"
                      :key="item.id"
                      :label="item.labelName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="ev.op"
                    :placeholder="$t('label.operator')"
                    class="select_two"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in ev.optionst_two"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <EditableCell
                    ref="editableCell"
                    :inputType="ev.inputType"
                    :options="selecArr"
                    :value="ev.val"
                    :origin-type="filter"
                    :prefix="prefix"
                    :fieldId="ev.fieldId"
                    :objid="ev.objid"
                    :min="0"
                    :max="1000000000000000000"
                    @remoteSearch="remoteSearch(ev, index)"
                    @selectes="ev.fieldId === '' ? '' : selectes(ev)"
                    @remoteMethods="remoteMethods"
                    style="width: 38%; position: relative"
                  >
                  </EditableCell>
                  <div style="width: 100px">
                    <span
                      class="btn_s"
                      @click="dels(index)"
                      v-if="objForm.list.length !== 1"
                      >-</span
                    >
                    <span
                      class="btn_s"
                      @click="adds"
                      v-if="objForm.list.length - 1 === index"
                      >+</span
                    >
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item style="margin: 6px 0 20px 13px; display: flex">
                <div style="float: left">
                  <el-checkbox
                    v-model="checked"
                    @change="checkBtn"
                    style="margin-right: 15px"
                  ></el-checkbox>
                  <!-- 高级筛选 -->
                  {{ $t("advancedconditions") }}
                </div>
                <textarea
                  name="text"
                  v-model="objForm.filter"
                  class="height_s"
                  autosize
                  :rows="1"
                  v-if="checkFlag"
                  style="
                    width: 352px;
                    margin-left: 10px;
                    border-radius: 3px;
                    float: left;
                    border: 1px solid #dcdfe6;
                    outline-color: none;
                  "
                >
                </textarea>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!-- <el-form-item label="选择字段" -->
              <el-form-item
                :label="$t('label.selectfield')"
                prop="viewSelectedFieldArr"
                style="margin-left: 12px"
              >
                <oneShuttle
                  ref="oneShuttle"
                  :viewUnselectedFieldList="viewUnselectedFieldList"
                  :viewSelectedFieldArr="viewSelectedFieldList"
                  @selFun="getSelFun"
                  v-if="this.selLength > 0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="visibRadio">
                <span class="setSize see_s">
                  <!-- 可见性 -->
                  {{ $t("label.tag.addtags.visibility") }}
                </span>
                <el-radio-group
                  v-model="objForm.visibRadio"
                  @change="visibBtn"
                  style="margin-left: 50px"
                >
                  <el-radio label="fsharenone">
                    <!-- '仅对我可见' -->
                    {{ $t("label_tabpage_onlymevisible") }}
                  </el-radio>
                  <el-radio label="fshareall">
                    <!-- 所有用户可见 -->
                    {{ $t("label_tabpage_allvisible") }}
                  </el-radio>
                  <el-radio label="fsharegroups">
                    <!-- '某些用户组可见' -->
                    {{ $t("label_tabpage_groupsVisiblez") }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!-- <el-form-item label="选择成员" -->
              <el-form-item
                :label="$t('vue_label_commonobjects_view_select_member')"
                v-show="visibFlag"
                style="margin-left: 12px; margin-top: 13px"
              >
                <Shuttle
                  ref="shuttles"
                  :selectedGroupList="selectedGroupList"
                  @memberFun="memberFun"
                />
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            size="small"
            @click="saveAsBtn"
            v-show="this.title === $t('label.view.introduce')"
          >
            <!-- 另存为 -->
            {{ $t("label.saveas") }}
          </el-button>
          <el-button type="primary" size="small" @click="saveView('objForm')">
            <!-- 保存 -->
            {{ $t("label.save") }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import sortDrop from "@/components/ViewBox/sortDrop.vue";
import oneShuttle from "@/components/ViewBox/oneShuttle.vue";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import filterValueInputType from "./../../../../../config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
import Shuttle from "@/components/shuttle/index.vue";
import {
  setValueTypeConversion,
  echoValueTypeConversion,
} from "@/config/filter/commaProcessing";
import {
  GetViewInfo,
  DeleteGroup,
  SaveView,
  GetViewGetSelectValue,
  GetCurrencyInfo,
  ResettingViewFieldMemory,
} from "../../../api";
import * as CommonObjApi from "../../../api";

export default {
  components: {
    sortDrop,
    oneShuttle,
    EditableCell,
    Shuttle,
  },
  props: {
    multiScreen: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Array,
    },
    tabName: {
      type: String,
    },
    objId: {
      type: String,
    },
    viewId: {
      type: String,
    },
    viewName: {
      type: String,
    },
    reportList: {
      // 对象对应的的报表列表
      type: Array,
      default: () => [],
    },
    loadingReport: {
      // 报表列表的loading
      type: Boolean,
      default: false,
    },
    isTag: {
      type: String,
    },
    ismodifyFalg: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
    },
    viewInfos: {
      type: Object,
    },
    daochuUrl: {
      type: String,
    },
    totalSize: {
      type: Number,
    },
    showScreen: {
      // 是否显示筛选器弹框
      type: Boolean,
      default: false,
    },
    isChart: {
      // 是否显示图表弹框
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // 报表权限信息
    reportOperation: function () {
      return this.$store.getters.getReportOperation;
    },
  },
  data() {
    //视图名称做校验
    var validatorLabel = (rule, value, cb) => {
      if (value.replace(/^\s+$/g, "")) {
        return cb();
      }
      cb(new Error("请填写正确的名称"));
    };
    return {
      shezhishow: true,
      loading: true,
      isShowOneShuttle: false,
      isShowShuttle: false,
      //排序
      activeIndex: "",
      // isActive: 1,
      sortFlag: false,
      //弹框
      title: "",
      dialogFormVisible: false,
      higChecked: false,
      moveCheck: false,
      formLabelWidth: "118px",
      //复制
      copyVisible: false,
      copyForm: {
        name: "",
      },
      copyWidth: "60px",
      //初始数据
      initData: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
        objid: "",
      },
      addFlag: true,
      dataId: 1,
      objForm: {
        label: "",
        filter: "", //高级筛选值
        screeRadio: "0",
        visibRadio: "fsharenone",
        createbyidccname: "", //创建人
        createdate: "", //创建时间
        lastmodifybyidccname: "", //最后修改人
        lastmodifydate: "", //最后修改时间
        list: [
          {
            fieldId: "",
            op: "",
            val: {
              value: "",
            },
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
            objid: "",
          },
        ],
      },
      isUsedForMobile: false,
      selectedFieldIds: "", //已选字段
      viewSelectedFieldList: [], //已选字段
      viewUnselectedFieldList: [], //未选字段
      selectedGroupList: [], //未选成员
      memberArr: "", //已选成员
      selLength: 0,
      checkFlag: false,
      visibFlag: false,
      //下拉框数据
      optionst_one: [],
      optionst_two: [],
      optionst_three: [
        {
          value: "3",
          label: "",
        },
      ],
      //穿梭框数据
      member: { flag: true },
      list: [],
      curInputType: "",
      mouseFlag: false,
      isDisabled: false,
      isNewDisabled: false,
      isDelete: false,
      deletName: "", //当前视图名称
      selecArr: [],
      newViewId: "",
      rules: {
        label: [
          {
            required: true,
            message: this.$i18n.t("setup_layout_label_required"),
            trigger: "blur",
          },
          { validator: validatorLabel, trigger: "blur" },
        ],
        visibRadio: [
          {
            required: true,
            message: this.$i18n.t("setup_layout_label_required"),
            trigger: "change",
          },
        ],
      },
      showSearchTable: false,
      fieldId: "",
      checked: false,
      rowIndex: 0,
      dialogVisible: false,
      filter: "filter",
      isTableView: false,
      isBoardView: false,
      isPlanView: false,
      isMultiScreen: false,
      isReportBoard: false,
      isXiaoshou: false,
      isTagManage: false,
      isChartsBoard: false,
      isFilterBoard: false,
      isPrint: false, //打印
      isDerive: false, //导出
      // 判断是否是项目任务页面需要的参数
      projectTasksObjectApi: "",
      isQueue: null, //是否启用队列
      queryQueueList: [], //队列列表
      queryQueueValue: "", //队列选项值
      isQueueSelect: false,
      textArr: "",
      searchChecked: false,
      secondDeleteTo: false, // 视图二次删除弹框
      markedWords: "", // 视图二次删除的提示语
      confirmdelete: false,
      isreportcreated: false, //判断是否显示报表按钮
    };
  },
  created() {
    CommonObjApi.getUserSetup().then((res) => {
      if (res.data.isCreateView === "true") {
        this.isNewDisabled = false;
      } else {
        this.isNewDisabled = true;
      }
    });
    this.checkQueueAndPartners();
  },
  methods: {
    //获取对象权限
    async getObjectPermission(id) {
      let params = {
        id: id,
      };
      let res = await CommonObjApi.getObjectPermission(params);
      this.isreportcreated = res.data.isreportcreated;
    },
    queueBtn(e) {
      if (e === "2") {
        this.isQueueSelect = true;
      } else if (e !== "0" && e !== "1") {
        this.objForm.screeRadio = "2";
        this.isQueueSelect = true;
        this.queryQueueValue = e;
      } else {
        this.isQueueSelect = false;
      }
    },
    //判断队列是否展示
    async checkQueueAndPartners() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.checkQueueAndPartner(params);
      if (res.result) {
        this.isQueue = res.data.isQueue;
        if (res.data.isQueue) {
          this.queryQueueLists();
        }
      }
    },
    //获取队列列表
    async queryQueueLists() {
      let params = {
        id: this.objId,
      };
      let res = await CommonObjApi.queryQueue(params);
      if (res.result) {
        this.queryQueueList = res.data;
      }
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      } else if (val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH") {
        this.getCountrySelectValue();
      } else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      }
    },
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      CommonObjApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selecArr = options;
        });
      });
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selecArr = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    remoteSearch(item, num) {
      this.rowIndex = num + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找多选的值
  
    changeSelect(row) {
      // eslint-disable-next-line no-unused-vars
      let value = {
        value: row.data.id,
      };
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.objForm.list[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    // 处理按钮点击事件
    handleClick(action) {
      if (action === "tableView") {
        //点击列表按钮
        if (this.isTableView === false) {
          this.isTableView = true;
          this.isBoardView = false;
          this.isPlanView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isTableView = false;
        }
      } else if (action === "boardView") {
        //点击看板按钮
        if (this.isBoardView === false) {
          this.isTableView = false;
          this.isBoardView = true;
          this.isPlanView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isBoardView = false;
        }
      } else if (action === "planView") {
        //点击计划按钮
        if (this.isBoardView === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isPlanView = true;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isBoardView = false;
        }
      } else if (action === "multiScreen") {
        //点击分屏按钮
        if (this.isMultiScreen === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isPlanView = false;
          this.isMultiScreen = true;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isMultiScreen = false;
        }
      } else if (action === "reportBoard") {
        //点击报表按钮
        if (this.isReportBoard === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = true;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isReportBoard = false;
        }
      } else if (action === "xiaoshou") {
        //点击销售漏斗按钮
        if (this.isXiaoshou === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = true;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isXiaoshou = false;
        }
      } else if (action === "tagManage") {
        //点击标签按钮
        if (this.isTagManage === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = true;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
        } else {
          this.isTagManage = false;
        }
      } else if (action === "chartsBoard") {
        //点击图表按钮
        if (this.isChartsBoard === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = true;
          this.isFilterBoard = false;
        } else {
          this.isChartsBoard = false;
        }
      } else if (action === "filterBoard") {
        //点击筛选器按钮
        if (this.isFilterBoard === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = true;
        } else {
          this.isFilterBoard = false;
        }
      } else if (action === "export") {
        //点击导出按钮
        if (this.isDerive === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
          this.isDerive = true;
        } else {
          this.isDerive = false;
        }
      } else if (action === "print") {
        //点击打印按钮
        if (this.isPrint === false) {
          this.isTableView = false;
          this.isBoardView = false;
          this.isMultiScreen = false;
          this.isReportBoard = false;
          this.isXiaoshou = false;
          this.isTagManage = false;
          this.isChartsBoard = false;
          this.isFilterBoard = false;
          this.isDerive = false;
          this.isPrint = true;
        } else {
          this.isPrint = false;
        }
      }
      this.$emit(action);
    },
    handleClicks() {},
    calendarClick() {
      this.$router.push({
        path: `/CalendarHeader`,
      });
    },
    //获取视图排序接口
    getOriginFields(sumObj) {
      sumObj.forEach((val) => {
        if (this.viewId === val.id) {
          this.deletName = val.label;
        }
      });
    },
    //子传父级(控制排序开关)
    sortFun(nArr) {
      if (nArr.refresh) {
        this.$parent.refreshView();
      }
      this.sortFlag = nArr.flag;
    },
    //获取可选字段
    getSelFun(n) {
      this.selectedFieldIds = n;
      // if (this.selectedFieldIds === "") {
      //   this.isShowOneShuttle = true;
      //   this.isShowShuttle = false;
      // } else if (this.selectedFieldIds.split(",").length > 10) {
      //   this.isShowOneShuttle = false;
      //   this.isShowShuttle = true;
      // } else {
      //   this.isShowOneShuttle = false;
      //   this.isShowShuttle = false;
      // }
    },
    //获取可选成员
    memberFun(n) {
      this.memberGroup = n;
    },
    // 获取视图详细信息
    getViewInfo() {
      const params = {
        //编辑时viewid传""
        viewId:
          this.title === this.$i18n.t("label.view.introduce")
            ? this.viewId
            : "",
        objId: this.objId,
      };
      GetViewInfo(params).then((res) => {
        // if (this.title === "编辑视图" || this.title === "复制视图") {
        if (
          this.title === this.$i18n.t("label.view.introduce") ||
          this.title === this.$i18n.t("vue_label_commonobjects_view_copy_view")
        ) {
          if (res.data.viewInfo) {
            let viewInfoList = res.data.viewInfo;
            //判断是否是复制视图名字
            if (
              this.title ===
              this.$i18n.t("vue_label_commonobjects_view_copy_view")
            ) {
              this.copyForm.name =
                viewInfoList.label + this.$i18n.t("vue_label_norm_duplicate");
            } else {
              this.copyForm.name = "";
            }
            //筛选条件所有人
            if (viewInfoList.filter === "2") {
              this.isQueueSelect = true;
            } else {
              this.isQueueSelect = false;
            }
            this.objForm.screeRadio = viewInfoList.filter;

            //名称
            this.objForm.label = viewInfoList.label;
            //高级筛选表单值
            if (
              res.data.conditionSelectedFieldList &&
              res.data.conditionSelectedFieldList[0] &&
              res.data.conditionSelectedFieldList[0].boolfilter
            ) {
              this.objForm.filter =
                res.data.conditionSelectedFieldList[0].boolfilter;
              this.checkFlag = true;
              this.checked = true;
            } else {
              this.objForm.filter = "";
              this.checkFlag = false;
              this.checked = false;
            }
            //创建人和修改人
            this.objForm.createbyidccname = viewInfoList.createbyidccname;
            this.objForm.createdate = viewInfoList.createdate;
            this.objForm.lastmodifydate = viewInfoList.lastmodifydate;
            this.objForm.lastmodifybyidccname =
              viewInfoList.lastmodifybyidccname;
            if (viewInfoList.isusedformobile === "true") {
              this.isUsedForMobile = true;
            } else {
              this.isUsedForMobile = false;
            }
            //可见性
            if (viewInfoList.accesstype == "fsharegroups") {
              this.objForm.visibRadio = "fsharegroups";
              this.visibFlag = true;
              this.selectedGroupList = res.data.selectedGroupList;
            } else if (viewInfoList.accesstype == "fshareall") {
              this.objForm.visibRadio = "fshareall";
              this.visibFlag = false;
            } else if (viewInfoList.accesstype == "fsharenone") {
              this.objForm.visibRadio = "fsharenone";
              this.visibFlag = false;
            }
          }
          //已选字段
          if (res.data.viewSelectedFieldList) {
            this.viewSelectedFieldList = res.data.viewSelectedFieldList;
            let selecteArr = [];
            res.data.viewSelectedFieldList.forEach((val) => {
              selecteArr.push(val.id);
            });
            this.selectedFieldIds = selecteArr.join(",");
          }
          //已选成员
          const selectedMembList = res.data.selectedGroupList;
          if (selectedMembList) {
            let newArr = [];
            selectedMembList.forEach((val) => {
              newArr.push(val.id);
            });
            this.memberGroup = newArr.join(",");
          }
          //筛选字段下拉框数据
          this.optionst_one = res.data.conditionAllFieldList;
          // 运算符下拉初始化加载
          if (res.data.conditionSelectedFieldList.length) {
            let arr = [];
            // eslint-disable-next-line no-unused-vars
            let currencyValueName = [];
            res.data.conditionSelectedFieldList.map((item) => {
              this.optionst_one.map((itm) => {
                if (item.fieldId === itm.id) {
                  let inputType =
                    filterValueInputType[
                      itm.schemefieldType === "L" ? "Q" : itm.schemefieldType
                    ];
                  // 选择框类型为查找多选字段时，才需要回显做处理
                  if (inputType === "multi-select") {
                    item.val = echoValueTypeConversion(item.val);
                  }
                  arr.push({
                    fieldId: item.fieldId,
                    op: item.op,
                    seq: item.seq,
                    val: {
                      value: item.val,
                    },
                    inputType: inputType,
                    optionst_two:
                      operator.TYPE[itm.schemefieldType].getScreeningOpeTypes(),
                  });
                }
              });
            });
            this.objForm.list = arr;
          } else {
            this.objForm.list = [
              {
                fieldId: "",
                op: "",
                val: {
                  value: "",
                },
                type: 1,
                id: 1,
                inputType: "input",
                optionst_two: [],
                objid: "",
              },
            ];
          }
        }
        //筛选字段下拉框数据
        this.optionst_one = res.data.conditionAllFieldList;
        //新建视图时已选字段为未选字段的name为name时
        if (this.title !== this.$i18n.t("label.view.introduce")) {
          //已选字段
          this.viewSelectedFieldList = res.data.viewUnselectedFieldList.filter(
            (itemVal) => {
              return itemVal.name === "name";
            }
          );
          //未选字段
          this.viewUnselectedFieldList =
            res.data.viewUnselectedFieldList.filter((itemVal) => {
              return itemVal.name !== "name";
            });
          this.selLength = this.viewUnselectedFieldList.length;
        } else {
          //未选字段
          this.viewUnselectedFieldList = res.data.viewUnselectedFieldList;
          this.selLength = this.viewUnselectedFieldList.length;
          //已选字段
          if (res.data.viewSelectedFieldList) {
            this.viewSelectedFieldList = res.data.viewSelectedFieldList;
          }
        }
        this.loading = false;
      });
    },
    //加载下拉选项
    loadSelect() {},
    //视图搜索显示隐藏
    fieldBtn() {
      this.fieldFlag = !this.fieldFlag;
    },
    //高级筛选显示隐藏
    checkBtn() {
      this.checkFlag = !this.checkFlag;
    },
    //初始化数据
    init() {
      this.objForm = {
        label: "",
        filter: "",
        screeRadio: "0",
        visibRadio: "fsharenone",
        list: [
          {
            fieldId: "",
            op: "",
            val: {
              value: "",
            },
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
          },
        ],
      };
    },
    //新建
    newlyBuild() {
      this.viewUnselectedFieldList = [];
      // 创建视图
      this.title = this.$i18n.t("label.profile.allowtocreateview");
      this.dialogFormVisible = true;
      this.visibFlag = false;
      this.selectedFieldIds = "";
      this.selectedGroupList = [];
      this.init();
      this.$nextTick(() => {
        if (this.$refs.oneShuttle !== undefined) {
          this.$refs.oneShuttle.searchVal = "";
        }
        if (this.$refs.shuttles !== undefined) {
          this.$refs.shuttles.searchVal = "";
        }
      });
      this.getViewInfo();
    },
    //编辑
    editBuild() {
      //编辑视图
      this.title = this.$i18n.t("label.view.introduce");
      this.objForm.filter = "";
      this.dialogFormVisible = true;
      this.getViewInfo();
      this.$nextTick(() => {
        if (this.$refs.oneShuttle || this.$refs.shuttles) {
          this.$refs.oneShuttle.searchVal = "";
          this.$refs.shuttles.searchVal = "";
        }
      });
    },
    //复制
    copy() {
      this.copyVisible = true;
    },
    //另存为
    saveAsBtn() {
      this.title = this.$i18n.t("label.saveas");
      this.copy();
      this.getViewInfo();
    },
    /*
      删除视图：
        viewId	是	string	视图Id
        objId	是	string	对象Id
        confirmdelete	否	string	确认删除，不填默认为否；
          当有图表以此视图为数据源时，且此参数不为true时，会提示有图表引用无法删除，
          点击确定可以再调这个接口传此参数为true，即可连图表一起删除
    */
    confirmDel() {
      const params = {
        viewId: this.viewId,
        objId: this.objId,
        confirmdelete: this.confirmdelete,
      };
      DeleteGroup(params)
        .then(() => {
          this.dialogVisible = false;
          this.secondDeleteTo = false;
          this.confirmdelete = false;
          this.$message({
            showClose: true,
            type: "success",
            //删除成功
            message: this.$i18n.t("label.ems.delete.success"),
          });
          // 删除成功
          this.$emit("getViewId", this.$i18n.t("label.ems.delete.success"));
        })
        .catch((error) => {
          this.markedWords = error.returnInfo;
          this.secondDeleteTo = true;
        });
    },
    // 视图二次删除确认
    secondDeleteConfirm() {
      this.confirmdelete = true; // 确认连同图表一起删除
      this.confirmDel();
    },
    // 删除视图弹框隐藏
    handleClose() {
      this.dialogVisible = false;
    },
    // 视图二次删除确认弹框隐藏
    secondDeleteClose() {
      this.secondDeleteTo = false;
      this.confirmdelete = false;
    },
    //视图设置下拉框出现/隐藏
    visibleBtn() {
      this.sortFlag = false;
    },
    //视图设置点击事件
    itemBtn(item) {
      if (item === this.$i18n.t("label.new")) {
        this.newlyBuild();
      }
      if (item === this.$i18n.t("label.clone")) {
        this.copy();
        //"复制视图"
        this.title = this.$i18n.t("vue_label_commonobjects_view_copy_view");
        this.getViewInfo();
      }
      if (item === this.$i18n.t("label.modify")) {
        this.editBuild();
      }
      if (item === this.$i18n.t("label.delete")) {
        this.dialogVisible = true;
      } //"重置列宽"
      if (item === this.$i18n.t("vue_label_commonobjects_view_reset_width")) {
        this.resetFun();
      }
      // 点击排序
      if (item === this.$i18n.t("label.sort")) {
        this.sortFlag = true;
        this.$refs.sort.getSortView();
      } else {
        this.sortFlag = false;
      }
      if (item === this.$i18n.t("vue_label_commonobjects_view_settings")) {
        this.$emit("actives");
      }
    },
    //重置列宽
    resetFun() {
      let data = {};
      if (this.ismodifyFalg === "true") {
        data = {
          viewId: this.viewId,
        };
      } else {
        data = {
          viewId: this.prefix + "_" + this.viewId,
        };
      }
      ResettingViewFieldMemory(data).then(() => {
        // 刷新表头
        this.$parent.getViewListHeader();
      });
    },
    noClicks() {
      if (this.ismodifyFalg === "true") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    //新增
    adds() {
      if (this.dataId < 10) {
        this.dataId += 1;
        this.objForm.list.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
      let an = " and ";
      if (this.objForm.list.length >= 2) {
        this.textArr = "";
        this.objForm.list.forEach((item, index) => {
          index++;

          this.textArr = this.textArr + an + index;
          this.objForm.filter = this.textArr.slice(4);
        });
      }
    },
    //删除
    dels(id) {
      this.dataId = id;
      this.objForm.list.splice(id, 1);
      this.$refs.editableCell.splice(id, 1);
      let an = " and ";
      if (this.objForm.list.length >= 2) {
        this.textArr = "";
        this.objForm.list.forEach((item, index) => {
          index++;

          this.textArr = this.textArr + an + index;
          this.objForm.filter = this.textArr.slice(4);
        });
      } else {
        this.objForm.filter = "";
      }
    },
    //根据筛选字段判断值
    selectChange(val, index) {
      this.fieldId = val;
      this.optionst_one.map((item) => {
        let keys = "";
        if (val === item.id) {
          this.objForm.list[index].op = "";
          this.objForm.list[index].val = {
            value: "",
          };
          if (item.schemefieldType === "Z") {
            keys = item.expressionType;
            this.objForm.list[index].optionst_two =
              operator.TYPE[item.expressionType].getScreeningOpeTypes();
          } else {
            keys = item.schemefieldType;
            this.objForm.list[index].optionst_two =
              operator.TYPE[item.schemefieldType].getScreeningOpeTypes();
          }
          this.objForm.list[index].op =
            this.objForm.list[index].optionst_two[0].id; // 设置默认操作为第一个选项
          this.objForm.list[index].inputType =
            filterValueInputType[keys === "L" ? "Q" : keys];
          this.selecArr = [];
          // 无法替换 接口返回
          if (item.schemefield_type == "ct") {
            GetCurrencyInfo().then((res) => {
              res.data.forEach((item) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.schemefield_type === "R") {
            this.getRecordTypes();
          } else {
            GetViewGetSelectValue({
              fieldId: val,
            }).then((res) => {
              res.data.forEach((item) => {
                (item.label = item.codekey), (item.value = item.codevalue);
              });
              this.selecArr = res.data;
            });
          }
        }
      });
    },
    getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      CommonObjApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
    //选定用户可见才显示可选成员
    visibBtn(e) {
      if (e === "fsharegroups") {
        this.visibFlag = true;
      } else {
        this.visibFlag = false;
      }
    },
    //保存新视图
    saveView() {
      this.$refs.shuttles.curGroupLists();
      this.$refs.objForms.validate((valid) => {
        if (valid) {
          // if (this.selectedFieldIds === "") {
          //   this.isShowOneShuttle = true;
          //   this.isShowShuttle = false;
          // } else if (this.selectedFieldIds.split(",").length > 10) {
          //   this.isShowOneShuttle = false;
          //   this.isShowShuttle = true;
          // } else {
          //   this.isShowOneShuttle = false;
          //   this.isShowShuttle = false;
          // }
          if (this.selectedFieldIds.split(",").length <= 15) {
            // this.isShowShuttle = false;
            let copyList = JSON.parse(JSON.stringify(this.objForm.list));
            // 对筛选条件中的value值做处理
            if (this.$refs.editableCell) {
              this.$refs.editableCell.forEach((item, index) => {
                if (item.editValue !== "" && item.editValue !== undefined) {
                  // 选项列表查找多选字段，获取的value为数组，需要转成后端需要的格式
                  if (copyList[index].inputType == "multi-select") {
                    copyList[index].val = setValueTypeConversion(
                      item.editValue
                    );
                  } else {
                    copyList[index].val = item.editValue;
                  }
                } else {
                  copyList[index].val = item.editValue;
                }
              });
            } else {
              copyList.forEach((item) => {
                item.val = item.val.value;
              });
            }
            // 删除copyList中的其他属性
            // 当copyList中没有fieldId就不用传conditionvals
            let hasFieldId=false
            copyList.forEach((item, i) => {
              delete copyList[i].type;
              delete copyList[i].inputType;
              delete copyList[i].id;
              delete copyList[i].optionst_two;
              if (item.seq) {
                delete copyList[i].seq;
              }
              if(item.fieldId){
                hasFieldId=true
              }
            });
            if (this.objForm.filter === null) {
              this.objForm.filter = "";
            }
            if (this.selectedFieldIds === "") {
              this.$message.error(
                this.$i18n.t("message_chooseatleastonefield")
              );
              return;
            }
            let params = {
              id: "",
              objId: this.objId, //对象id
              label: this.objForm.label, //名称
              filter:
                this.radio === "2"
                  ? this.queryQueueValue
                  : this.objForm.screeRadio, //全部/我的
              accessibleuserset: this.memberGroup, //已选成员数据
              selectedFieldIds: this.selectedFieldIds, //已选字段
              conditionVals: hasFieldId? JSON.stringify({
                data: copyList, //筛选器
                filter: this.objForm.filter,
                mainObjId: this.objId,
              }):null, //筛选
              accesstype: this.objForm.visibRadio, //可见用户组
              isUsedForMobile: String(this.isUsedForMobile), //是否应用到移动端
            };
            // 创建视图
            if (
              this.title === this.$i18n.t("label.profile.allowtocreateview")
            ) {
              params.id = "";
              SaveView(params).then((res) => {
                if (res.result === true) {
                  this.$message({
                    showClose: true,
                    type: "success",
                    // 保存成功
                    message: this.$i18n.t("label.search.saveok"),
                  });
                  this.newViewId = res.data.id;
                  this.$emit("getViewId", this.newViewId);
                } else {
                  this.$message({
                    showClose: true,
                    type: "error",
                    // 保存失败
                    message: this.$i18n.t("savefail"),
                  });
                }
              });
              this.dialogFormVisible = false;
              // 编辑视图
            } else if (this.title === this.$i18n.t("label.view.introduce")) {
              params.id = this.viewId;
              params.conditionVals = hasFieldId? JSON.stringify({
                data: copyList, //筛选器
                filter: this.objForm.filter,
                mainObjId: this.objId,
              }):null, //筛选
              SaveView(params).then((res) => {
                if (res.result === true) {
                  this.$message({
                    showClose: true,
                    type: "success",
                    // 保存成功
                    message: this.$i18n.t("label.search.saveok"),
                  });
                  this.$emit("getViewId", this.viewId);
                } else {
                  this.$message({
                    showClose: true,
                    type: "error",
                    // 保存失败
                    message: this.$i18n.t("savefail"),
                  });
                }
              });
              this.dialogFormVisible = false;
            }
          } else {
            this.$message.error(
              this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
            );
            // this.isShowShuttle = true;
            return false;
          }
        } else {
          return false;
        }
      });
    },
    copySave() {
      //'复制视图'
      // if (this.title === this.$i18n.t('vue_label_commonobjects_view_copy_view')) {
      let copyList = JSON.parse(JSON.stringify(this.objForm.list));
      // 当copyList中没有fieldId就不用传conditionvals
      let hasFieldId=false
      copyList.forEach((item, i) => {
        delete copyList[i].type;
        delete copyList[i].inputType;
        delete copyList[i].id;
        delete copyList[i].optionst_two;
        if (item.seq) {
          delete copyList[i].seq;
        }
        if(item.fieldId){
                hasFieldId=true
              }
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          copyList[index].val = item.editValue;
        });
      } else {
        copyList.forEach((item) => {
          item.val = item.val.value;
        });
      }
      let params = {
        id: "",
        objId: this.objId, //对象id
        label: this.copyForm.name, //名称
        filter:
          this.radio === "2" ? this.queryQueueValue : this.objForm.screeRadio, //全部/我的
        accessibleuserset: this.memberGroup, //已选成员数据
        selectedFieldIds: this.selectedFieldIds, //已选字段
        conditionVals: hasFieldId?JSON.stringify({
          data: copyList, //筛选器
          filter: "",
          mainObjId: this.objId,
        }):null, //筛选
        accesstype: this.objForm.visibRadio, //可见用户组
        isUsedForMobile: String(this.isUsedForMobile), //是否应用到移动端
      };
      SaveView(params).then((res) => {
        if (res.result === true) {
          this.$message({
            showClose: true,
            type: "success",
            // 保存成功
            message: this.$i18n.t("label.search.saveok"),
          });
          this.newViewId = res.data.id;
          this.$emit("getViewId", this.newViewId);
        } else {
          this.$message({
            showClose: true,
            type: "error",
            // 保存失败
            message: this.$i18n.t("savefail"),
          });
        }
      });
      this.copyInfo();
      // }
    },
    //关闭编辑和另存为弹窗
    copyInfo() {
      this.copyVisible = false;
      this.dialogFormVisible = false;
    },
    // 转至报表列表
    jumpReportList() {
      this.$router.push({
        path: "/reportObject/reportList",
      });
    },
    // 跳转至单个报表
    jumpReport(item) {
      // 若是没有运行权限，则不可跳转
      if (!this.reportOperation.run) {
        // 用户暂无权限
        this.$message.info(this.$i18n.t("label.weixin.donot.permission"));
        return;
      }
      this.$router.push({
        path: `/reportObject/editReport/run/${item.reportId}/${item.folderId}`,
        query: {},
      });
    },
    //销售漏斗按钮
    funnelBtn(item) {
      if (item === this.$i18n.t("label.stdashbord.xsld.title")) {
        // this.newlyBuild()
        this.$router.push(`/commonObjects/hopper`);
      }
      if (item === this.$i18n.t("vue_label_salesadvance")) {
        this.$router.push(`/commonObjects/upgrade-hopper`);
      }
    },
    // 打印按钮
    print() {
      localStorage.getItem("openWater") == "true";
      window.open(
        `#/print-table/${this.objId}/${this.prefix}/${this.projectTasksObjectApi}/${this.viewId}`,
        "_parent - URL",
        "height=600, minwidth=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
    },

    // 导出按钮
    handle() {
      var link = document.createElement("a");
      var num = this.daochuUrl.concat("0");
      link.href = num;
      link.setAttribute("download", num);
      link.click();
      if (this.totalSize > 500) {
        this.$message({
          message: "单次最多导出500条记录，已为您导出前500条。",
          type: "warning",
        });
      }
    },
  },
  watch: {
    showButtons() {},
    viewSelectedFieldLisvalt() {},
    viewId(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.viewId = val;
      this.noClicks();
    },
    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.projectTasksObjectApi = route.params.objectApi;
        this.getObjectPermission(route.params.objId);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  display: inline-block;
}
.el-button-group {
  /*display: inline-block;*/
  // vertical-align: baseline;
  // height: 8px;
  height: 29px;
  line-height: 18px;
  .el-button {
    display: inline-block;
  }
}

.viewButtons {
  ::v-deep .editableCell {
    height: 33px;
  }
  // .el-button-group {
  //   vertical-align: baseline;
  // }
  .boxCard {
    width: 120px;
    position: absolute;
    right: 0;
    z-index: 10;

    ::v-deep .el-card__body {
      padding: 12px;
    }

    .textItem {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .createBtn {
    height: 30px;
    margin-right: 10px;
    background: #006dcc;
  }

  .top-btns {
    border: 1px solid #dedcda;
    border-radius: 3px;
    height: 30px;
    line-height: 27px;
    background: #ffff;
    li {
      font-size: 12px;
      border-right: 1px solid #dedcda;
      .btnName {
        padding: 0 12px;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: #006dcc;
      }

      .dividingLine {
        font-size: 16px;
        color: #c8c6c6;
      }

      .icon {
        font-size: 12px;
        color: #979797;
        padding-right: 12px;
      }
    }
  }

  .line-two-btn {
    .btn {
      padding: 0;
      height: 29px;
      padding: 0 4px;
      border: 1px solid #dedcda;
    }

    img {
      width: 18px;
      height: 18px;
    }

    .group-btn {
      // margin-top: -20px;
      padding: 0 4px;
      width: 30px;
      background-size: auto auto;
      background-repeat: no-repeat;
      background-position: 4px;
    }

    .in-left {
      // 圆角边框
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      margin-left: 10px;
      width: 30px;
      background-size: 56%;
      background-repeat: no-repeat;
      background-position: 6px;
    }

    .in-right {
      margin-right: 10px;
      width: 30px;
      background-size: auto auto;
      background-repeat: no-repeat;
      background-position: 4px;
    }
  }
}
.el-dropdown-menu {
  min-width: 110px;
  p {
    font-weight: 700;
    text-align: left;
    padding-left: 15px;
    margin: 0;
    font-size: 12px;
    padding-bottom: 6px;
  }
  .el-dropdown-menu__item {
    height: 25px;
    line-height: 25px;
    text-align: left;
    font-size: 12px;
    padding-left: 15px;
    &.active {
      background-color: rgb(0, 109, 204);
      color: #fff;
    }
  }
  .set_wrap {
    width: 175px;
    height: auto;
    margin-top: 40px;
    box-shadow: 2px 2px 10px #ccc;
    padding-top: 8px;
    position: absolute;
    top: 42px;
    left: 50px;
    background: #fff;
    p {
      border-bottom: 1px solid #ccc;
      line-height: 30px;
      height: 30px;
      padding-left: 15px;
    }
  }
}
//弹窗
.newBox ::v-deep .el-dialog__header {
  text-align: left !important;
  border-bottom: 1px solid #ccc !important;
  color: #000;
  font-weight: 400;
  height: 65px;
}

.newBox ::v-deep .el-form-item__label {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.screen_s > div {
  margin-bottom: 0 !important;
}
::v-deep .screen_s .editableCell {
  .el-select__tags {
    flex-wrap: unset;
    overflow-x: overlay;
    top: 53%;
    height: 38px;
    overflow-y: hidden;
  }
  .el-input .el-input__inner {
    height: 30px !important;
  }
  .el-tag {
    margin: 0 5px !important;
  }
}
.screen_s ::v-deep .el-form-item__content {
  display: flex;
  margin-left: 133px !important;
  align-items: center;
}

.el-form-item {
  margin-bottom: 0;
}

.select_one {
  width: 180px;
  margin: 6px;
  height: 30px;
}

.select_two {
  width: 140px;
  margin: 6px 12px 6px 6px;
  height: 30px;
}

.btn_s {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}

.copyBox ::v-deep .el-dialog {
  width: 333px;
}
.newBox ::v-deep .el-dialog {
  width: 750px;
  height: 708px;
  margin-top: 10vh;
  .label_name .el-form-item__label {
    margin-right: 12px;
    margin-bottom: 28px;
  }
  .label_name .el-form-item__error {
    margin-top: -20px;
    margin-left: 15px;
  }
  .input_name,
  .input_name .el-input__inner {
    width: 183px;
  }
  .el-input__inner {
    height: 33px;
  }
}
.newBox ::v-deep .el-dialog__footer {
  border-top: 1px solid #ccc;
  height: 92px;
}
.newBox .dialog-footer {
  margin-top: 20px;
  text-align: right;
}
.newBox ::v-deep .el-dialog__body {
  height: 550px;
  overflow-y: auto;
}
.setSize {
  font-size: 12px;
  margin-right: 20px;
  position: relative;
}
.see_s {
  margin-left: -105px;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.newBox ::v-deep .el-radio__label {
  font-size: 12px;
}
.tipsBox {
  width: 10px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 12px;
  left: -57px;
}
.newBox {
  .mobileFilter {
    margin-left: 12px;
    ::v-deep .el-checkbox__label {
      font-size: 12px;
    }
  }
}
.newBox ::v-deep .el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.newBox ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #006dcc;
}
.newBox ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #006dcc;
}
</style>

<style lang="scss">
.el-popover.popper_report {
  .moreMenus {
    margin-bottom: 0;
    .top_menu {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:last-child {
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
      }
    }
    li {
      height: 30px;
      line-height: 30px;
      &:hover {
        color: #006dcc;
        cursor: pointer;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.el-popper {
  ::v-deep li {
    &:hover {
      color: #006dcc;
      cursor: pointer;
      background: #f3f2f2;
    }
  }
}
</style>
